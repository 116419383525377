<template>
  <p>You are being logged out.</p>
</template>
<script>
import Authentication from "@/lib/Authentication";

export default {
  name: "AuthLogoutController",
  created() {
    Authentication.logout();
    this.$router.push({ name: "auth-login" });
  },
};
</script>

<style scoped></style>
